<template>
  <div>
    <b-row>
      <b-col md="6" xl="6" class="mb-1">
        <b-card>
          <b-card-header
            style="padding-top: 0px; padding-right: 0px; padding-left: 0px"
          >
            <div class="d-flex align-items-center">
              <b-card-title>
                <h4><b style="color: cornflowerblue">Pembahasan Soal</b></h4>
              </b-card-title>
            </div>
            <b-nav>
              
            </b-nav>
          </b-card-header>
          <b-form
            method="post"
            enctype="multipart/form-data"
            @submit.prevent="updateData(question)"
          >
            <b-row>
              <b-col md="6" xl="12" class="mb-1">
                <label for="textarea-default"><b style="color:red">Kategori</b></label>
                <v-select
                  name="register-kampus"
                  placeholder="Cari Nama Kategori"
                  v-model="question.id_category"
                  :value="id"
                  :reduce="(nama) => nama.id"
                  :options="listCategories"
                  label="nama"
                  disabled
                >
                </v-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" xl="12" class="mb-1">
                <label for="textarea-default"><b style="color:red">Pelajaran</b></label>
                <v-select
                  name="register-kampus"
                  placeholder="Cari Nama Pelajaran"
                  v-model="question.id_study"
                  :value="id"
                  :reduce="(nama) => nama.id"
                  :options="listStudies"
                  label="nama"
                  disabled
                >
                </v-select>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6" xl="12" class="mb-1">
                <label for="textarea-default"><b style="color:red">Isi Soal</b></label>
               <p>
                {{question.question}}
              </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" xl="12" class="mb-1">
                <label for="textarea-default"><b style="color:red">Kata Kunci</b></label>
                <p>
                {{question.kata_kunci}}
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" xl="12" class="mb-1">
                <label for="textarea-default"><b style="color:red">Pembahasan</b></label>
              
                <p>
                  {{question.pembahasan}}
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" xl="12" class="mb-1">
                <label for="textarea-default"><b style="color:red">Referensi</b></label>
                <p>
               {{question.referensi}}
              </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" xl="12" class="mb-1">
                <label for="textarea-default"><b style="color:red">Pembahasan Video</b></label>
              </b-col>
            </b-row>
            <b-col md="6" xl="1" class="mb-1">
              <b-form-input
                hidden
                id="basicInput"
                v-model="question.hidden_video"
                placeholder="Name Video"
              />
            </b-col>
          </b-form>
        </b-card>
      </b-col>
      <b-col md="6" xl="6" class="mb-1">
        <b-card>
          <b-card-header
            style="padding-top: 0px; padding-right: 0px; padding-left: 0px"
          >
            <div class="d-flex align-items-center">
              <b-card-title>
                <h4><b style="color: cornflowerblue">Pilihan Jawaban</b></h4>
              </b-card-title>
            </div>
            <b-nav>
              <b-nav-item>
                <router-link to="/user/list_hasil_tryout">
                  <b-button variant="primary">
                    <feather-icon icon="ArrowLeftIcon" style="color: white" />
                    <span class="align-right"
                      ><b style="color: white"> Kembali</b></span
                    >
                  </b-button>
                </router-link>
              </b-nav-item>
            </b-nav>
          </b-card-header>
          <b-form
            method="post"
            enctype="multipart/form-data"
            @submit.prevent="addAnswerData(question)"
          >
            <b-table-simple hover small caption-top responsive>
             
              <colgroup>
                <col />
                <col />
              </colgroup>
              <colgroup>
                <col />
                <col />
                <col />
              </colgroup>
              <colgroup>
                <col />
                <col />
              </colgroup>
              <b-thead head-variant="light">
                <b-tr>
                  <b-th colspan="10" class="text-center" width="90%">
                    Pilihan Ganda
                  </b-th>
                  <b-th colspan="1" class="text-center"> Jawab </b-th>
                </b-tr>
              </b-thead>
              <b-tbody
                v-for="(answer, index) in listAnswers"
                :key="answer.id"
                class="space_good"
              >
                <b-tr>
                  <b-td class="text-left" colspan="10" width="90%">
                    {{ answer.answer }}
                  </b-td>
                  <b-td class="text-center" v-if="answer.is_correct == 0" variant="danger">
                  <b v-if=" index + 1 == 1"> (A)</b>
                  <b v-else-if=" index + 1 == 2"> (B)</b>
                  <b v-else-if=" index + 1 == 3"> (C)</b>
                  <b v-else-if=" index + 1 == 4"> (D)</b>
                  <b v-else-if=" index + 1 == 5"> (E)</b> 
                  Salah
                  </b-td>
                  <b-td class="text-center" v-else-if="answer.is_correct == 1" variant="success">
                  <b v-if=" index + 1 == 1"> (A)</b>
                  <b v-else-if=" index + 1 == 2"> (B)</b>
                  <b v-else-if=" index + 1 == 3"> (C)</b>
                  <b v-else-if=" index + 1 == 4"> (D)</b>
                  <b v-else-if=" index + 1 == 5"> (E)</b> 
                    Benar
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-row>
              <b-col md="6" xl="12" class="mb-1">
                <b-form-input
                hidden
                  id="ID Jawaban"
                  v-model="answer_id"
                  placeholder="ID Jawaban"
                />
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BFormFile,
  BTd,
  BTbody,
  BTfoot,
  BCard,
  BButton,
  BForm,
  BFormInput,
  BToast,
  BCardHeader,
  BNav,
  BCardTitle,
  BFormSelect,
  BNavItem,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BCard,
    BCardTitle,
    BTableSimple,
    BThead,
    BTr,
    BFormInput,
    quillEditor,
    BForm,
    BFormFile,
    BTh,
    BToast,
    BTd,
    ToastificationContent,
    BTbody,
    Swal,
    BTfoot,
    BButton,
    BCardHeader,
    BNav,
    BFormTextarea,
    BNavItem,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      id: this.$route.params.id,
      listCategories: [],
      listStudies: [],
      listAnswers: [],
      id_category: "",
      id_study: "",
      answer_id:"",
      question: "",
      pembahasan: "",
      kata_kunci: "",
      video: null,
      isEdit: "Tidak",
      isLoading: false,
      progressBar: 0,
      uploadReady: true,
      files: [],
      videos: [],
      hidden_video: "",
      referensi: "",
      getUrl: "",
      is_correct: "0",
      option_status: [
        { text: "Benar", value: "1" },
        { text: "Salah", value: "0" },
      ],
      answer: "",
    };
  },
  async created() {
    await this.$http
      .get(
        process.env.VUE_APP_BACKEND_URL +
          `questions/search/${this.$route.params.id}`
      )
      .then((response) => {
        this.question = response.data.data;
        // console.log("cek test", this.question);
      });
  },
  async mounted() {
    this.checkAuth();
    await this.getCategories();
    this.getStudies();
    this.getAnswers();
    this.getUrl = process.env.VUE_APP_URL;
  },
  methods: {
    checkAuth() {
      var sessionCheck = localStorage.getItem("Uid");
      if (sessionCheck == null || sessionCheck == "") {
        this.$router.push({ name: "login" });
      }
    },
    async updateData(question) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("video", this.video);
      formData.append("id_category", (this.id_category = question.id_category));
      formData.append("id_study", (this.id_study = question.id_study));
      formData.append("question", (this.question = question.question));
      formData.append("pembahasan", (this.pembahasan = question.pembahasan));
      formData.append("referensi", (this.referensi = question.referensi));
      formData.append(
        "hidden_video",
        (this.hidden_video = question.hidden_video)
      );
      formData.append("kata_kunci", (this.kata_kunci = question.kata_kunci));
      await Swal.fire({
        title: "Apa sudah selesai?",
        text: "Perubahan data disesuaikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, sudah selesai!",
      })
        .then((result) => {
          if (result.value) {
            this.$http
              .post(
                process.env.VUE_APP_BACKEND_URL +
                  `questions/update/${this.$route.params.id}`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                  onUploadProgress: function (progressEvent) {
                    this.progressBar = parseInt(
                      Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      )
                    );
                  }.bind(this),
                }
              )
              .then((result) => {
                this.$router.push({ name: "list_question" });
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Notification",
                      icon: "CheckCircleIcon",
                      text: "Simpan Perubahan Data",
                      variant: "success",
                    },
                  },
                  {
                    timeout: 5000,
                  },
                  {
                    position: "top-right",
                  }
                );
                setTimeout(() => {
                  this.videoReset();
                  this.isLoading = false;
                  this.progressBar = 0;
                }, 2000);
              });
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Notification",
                  icon: "CheckCircleIcon",
                  text: "Batal Perubahan Data",
                  variant: "warning",
                },
              },
              {
                timeout: 5000,
              },
              {
                position: "top-right",
              }
            );
          }
        })
        .then((result) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    cancelButton() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "CheckCircleIcon",
            text: "Batal Ubah Data",
            variant: "info",
          },
        },
        {
          timeout: 5000,
        },
        {
          position: "top-right",
        }
      );
      this.$router.go();
      setTimeout(() => {
        this.videoReset();
        this.isLoading = false;
        this.progressBar = 0;
      }, 2000);
    },
    getCategories() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "categories/view")
        .then((res) => {
          this.listCategories = res.data.data;
        });
    },
    getStudies() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "studies/view")
        .then((res) => {
          this.listStudies = res.data.data;
        });
    },
    videoUpload(event) {
      this.video = event.files[0];
    },
    videoReset() {
      this.uploadReady = false;
      this.$nextTick(() => {
        this.uploadReady = true;
      });
    },

    getAnswers() {
      this.$http
      .get(
        process.env.VUE_APP_BACKEND_URL +
          `answers/search/${this.$route.params.id}`
      )
        .then((res) => {
          this.listAnswers = res.data.data;
          // console.log("asdasd",this.listAnswers)
        });
    },
    async addAnswerData() {
      //   e.preventDefault();
      const formData = new FormData();
      formData.append("questions_id", this.id);
      formData.append("answer", this.answer);
      formData.append("is_correct", this.is_correct);
      await this.$http
        .post(process.env.VUE_APP_BACKEND_URL + "answers/create", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "CheckCircleIcon",
                text: "Berhasil Add Data",
                variant: "success",
              },
            },
            {
              timeout: 5000,
            },
            {
              position: "top-right",
            }
          );
          this.getAnswers()
          this.answer = "";
          this.is_correct = 0;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    editTask(id, answer, is_correct) {
      this.answer_id = id;
      this.answer = answer;
      this.is_correct = is_correct;
      this.isEdit = "Ya";
    },

    async updateAnswersData() {
      const formData = new FormData();
      formData.append("questions_id", this.id);
      formData.append("answer", this.answer);
      formData.append("is_correct", this.is_correct);
      await
       Swal.fire({
        title: "Apa sudah selesai?",
        text: "Perubahan data disesuaikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, sudah selesai!",
      })
        .then((result) => {
          if (result.value) {
            this.$http
              .post(
                process.env.VUE_APP_BACKEND_URL +
                  `answers/update/${this.answer_id}`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((result) => {
                this.answer = "";
                this.is_correct = 0;
                this.isEdit = "Tidak";
                this.getAnswers();
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Notification",
                      icon: "CheckCircleIcon",
                      text: "Simpan Perubahan Data",
                      variant: "success",
                    },
                  },
                  {
                    timeout: 5000,
                  },
                  {
                    position: "top-right",
                  }
                );
              });
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Notification",
                  icon: "CheckCircleIcon",
                  text: "Batal Perubahan Data",
                  variant: "warning",
                },
              },
              {
                timeout: 5000,
              },
              {
                position: "top-right",
              }
            );
          }
        })
        .then((result) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    async deleteAnswersData(id) {
      await Swal.fire({
        title: "Ingin Hapus Data?",
        text: "Data akan dihapus?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, sudah selesai!",
      })
        .then((result) => {
          if (result.value) {
            this.$http
              .delete(
                process.env.VUE_APP_BACKEND_URL + `answers/delete/${id}`
              )
              .then((result) => {
                this.getAnswers();
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Notification",
                      icon: "CheckCircleIcon",
                      text: "Hapus Data",
                      variant: "danger",
                    },
                  },
                  {
                    timeout: 5000,
                  },
                  {
                    position: "top-right",
                  }
                );
              });
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Notification",
                  icon: "CheckCircleIcon",
                  text: "Batal Hapus Data",
                  variant: "warning",
                },
              },
              {
                timeout: 5000,
              },
              {
                position: "top-right",
              }
            );
          }
        })
        .then((result) => {})
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>


<style>
.space_good {
  white-space: pre-wrap;
}
</style>